import React, { useEffect, useState } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import SEO from '../components/seo'
import AuthForm from '../components/AuthForm/AuthForm'
import {
  authenticateShopifyCustomer,
  sendForgotPWEmail,
} from '../helpers/requests'
import Cookies from 'js-cookie'
import Modal from 'react-modal'
import './account.scss'

const LoginPage = () => {
  const [authErrorMessage, setAuthErrorMessage] = useState('')
  const [modalIsOpen, setIsOpen] = useState(false)
  const [forgotPWEmail, setForgotPWEmail] = useState('')
  useEffect(() => {
    const cartQuantity = document.querySelector('.cart-quantity')
    cartQuantity.classList.remove('black')
  }, [])
  const submitForm = values => {
    console.log('submit')
    authenticateShopifyCustomer(values.email, values.password).then(res => {
      // set cookie with access token / exp.date
      console.log(res)
      const { customerAccessTokenCreate } = res.data.data
      if (!customerAccessTokenCreate.userErrors.length) {
        const {
          accessToken,
          expiresAt,
        } = customerAccessTokenCreate.customerAccessToken
        console.log(accessToken, expiresAt)
        const expiryDate = new Date(expiresAt)
        Cookies.set('KEPT_SESS', accessToken, { expires: expiryDate })
        window.location.href = '/account'
      } else {
        setAuthErrorMessage('Invalid credentials')
        setTimeout(() => setAuthErrorMessage(''), 10000)
      }
    })
  }
  const submitForgotPW = e => {
    e.preventDefault()
    sendForgotPWEmail(forgotPWEmail).then(res => {
      if (!res.data.data.customerRecover.customerUserErrors.length) {
        setIsOpen(false)
        setAuthErrorMessage('Reset sent successfully!')
        setTimeout(() => setAuthErrorMessage(''), 10000)
      }
    })
  }
  const modalStyles = {
    content: {
      top: '45%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '10',
      backgroundColor: '#152622',
      borderRadius: '0',
      padding: '2rem 2.5rem 3rem 2rem',
    },
  }
  return (
    <div>
      <SEO title="Login" />
      <div className="container text-center">
        <div className="row no-gutters pt-50 collection-header">
          <h1 className="ma u-h0">Login</h1>
        </div>
      </div>
      <AuthForm
        type="login"
        submitForm={submitForm}
        authErrorMessage={authErrorMessage}
        setAuthErrorMessage={setAuthErrorMessage}
      />
      <div className="auth-form tr">
        <div className="forgot-pw-trigger" onClick={() => setIsOpen(true)}>
          Forgot password?
        </div>
        <Link to="/register">Sign Up instead</Link>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Example Modal"
        style={modalStyles}
      >
        <h2 className="u-h0">Forgot Password</h2>
        <button className="close-btn" onClick={() => setIsOpen(false)}>
          &times;
        </button>
        <form onSubmit={submitForgotPW}>
          <div className="row">
            <input
              className="field"
              placeholder="Email"
              value={forgotPWEmail}
              onChange={e => setForgotPWEmail(e.target.value)}
            />
            <button>
              <span className="roboto gradient-text">Reset</span>
            </button>
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default LoginPage
